import React, { useState, useEffect } from 'react';
import { Brain, AlertTriangle, Rocket, Shield, TrendingUp } from 'lucide-react';
import OpenAI from 'openai';

const buildAnalysisPrompt = (tokenData, analysis) => `You are an expert crypto trader specializing in Solana tokens. Be direct and don't sugar coat your analysis.

Token Data:
- Name: ${tokenData?.token?.name || 'Unknown'}
- Symbol: ${tokenData?.token?.symbol || 'Unknown'}
- Market Cap: ${tokenData?.pools?.[0]?.marketCap?.usd || 'Unknown'}
- Liquidity: ${tokenData?.pools?.[0]?.liquidity?.usd || 'Unknown'}
- Holders: ${tokenData?.total || 'Unknown'}
- Security Score: ${analysis?.score || 'N/A'}/100

1. 🎯 Meta Score (0-10):
2. 💰 Potential:
3. 🚩 Red Flags:
4. 💎 Bullish Signals:
5. 🎮 Trade Strategy:
6. 🔮 Prediction:`;

const AIAnalysis = ({ tokenData, analysis }) => {
  const [aiAnalysis, setAiAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const analyzeToken = async () => {
      if (!tokenData) return;

      setIsLoading(true);
      setError(null);

      const client = new OpenAI({
        apiKey: "xai-r02SCIV2mSWjSLExKhi4XtzqiGhVBFZfXwAzGwXmimMHsQuc13KupYcF5qKcxpNehTeLL0FxJrvhRzCo",
        baseURL: "https://api.x.ai/v1",
        dangerouslyAllowBrowser: true
      });

      try {
        const completion = await client.chat.completions.create({
          model: "grok-beta",
          messages: [{
            role: "user",
            content: buildAnalysisPrompt(tokenData, analysis)
          }],
          temperature: 0.8,
          max_tokens: 1000
        });

        const response = completion.choices[0]?.message?.content;
        if (!response) throw new Error('No analysis generated');
        setAiAnalysis(response);
      } catch (err) {
        setError('AI analysis failed: ' + err.message);
      } finally {
        setIsLoading(false);
      }
    };

    analyzeToken();
  }, [tokenData, analysis]);

  if (isLoading) {
    return (
      <div className="border border-teal-700/20 rounded p-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Brain className="h-4 w-4 text-teal-700 animate-pulse" />
            <span className="text-teal-700">Smart AI analyzing token...</span>
          </div>
          <Rocket className="h-4 w-4 text-teal-700 animate-bounce" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="border border-red-500/20 rounded p-2">
        <div className="flex items-center space-x-2 text-red-500">
          <AlertTriangle className="h-4 w-4" />
          <span className="text-sm">{error}</span>
        </div>
      </div>
    );
  }

  if (!aiAnalysis) return null;

  const sections = aiAnalysis.split('\n').filter(line => line.trim());

  return (
    <div className="border border-teal-700/20 rounded p-2">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Brain className="h-5 w-5 text-teal-700" />
          <span className="text-teal-700 font-bold">Smart AI Analysis</span>
        </div>
        <div className="flex items-center space-x-2">
          <Shield className="h-4 w-4 text-teal-700" />
          <TrendingUp className="h-4 w-4 text-teal-700" />
        </div>
      </div>
      
      <div className="space-y-4">
        {sections.map((section, index) => (
          <div key={index} className="border-l-2 border-teal-700/20 pl-3">
            <span className="text-gray-400 text-sm whitespace-pre-line">{section}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AIAnalysis;