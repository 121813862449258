import React, { useState } from 'react';
import { Rocket, AlertTriangle } from 'lucide-react';

const LaunchPad = () => {
  const [formData, setFormData] = useState({
    name: '',
    ticker: '',
    description: '',
    telegram: '',
    twitter: '',
    discord: '',
    website: '',
    privateKey: '',
    customMint: ''
  });

  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Token launch data:', formData);
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="font-mono bg-white border border-teal-700/50 p-4 md:p-6 rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-2">
            <Rocket className="h-6 w-6" />
            <h2 className="text-2xl font-bold">Bundler Launchpad</h2>
          </div>
          <span className="text-amber-400 text-sm">Accelerate your Pump.Fun launches! ($SUB holders only)</span>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Token Details */}
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
                placeholder="Token Name"
              />
              <input
                type="text"
                name="ticker"
                value={formData.ticker}
                onChange={handleChange}
                className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
                placeholder="Token Ticker"
              />
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700 h-24 resize-none"
                placeholder="Token Description"
              />
            </div>

            {/* Social Links */}
            <div className="space-y-4">
              <input
                type="text"
                name="telegram"
                value={formData.telegram}
                onChange={handleChange}
                className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
                placeholder="Telegram Link"
              />
              <input
                type="text"
                name="twitter"
                value={formData.twitter}
                onChange={handleChange}
                className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
                placeholder="Twitter Link"
              />
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="discord"
                  value={formData.discord}
                  onChange={handleChange}
                  className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
                  placeholder="Discord Link"
                />
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
                  placeholder="Website"
                />
                
              </div>
            </div>
          </div>

          {/* Advanced Settings */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="password"
              name="privateKey"
              value={formData.privateKey}
              onChange={handleChange}
              className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
              placeholder="Bundle Buy (Optional)"
            />
                        <input
              type="password"
              name="privateKey"
              value={formData.privateKey}
              onChange={handleChange}
              className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
              placeholder="Developer Buy"
            />
            <input
              type="text"
              name="customMint"
              value={formData.customMint}
              onChange={handleChange}
              className="w-full bg-white border border-teal-700/30 rounded px-6 py-3 text-teal-700 placeholder-teal-700/30 focus:outline-none focus:border-teal-700"
              placeholder="Custom Token Address (Optional)"
            />
            
          </div>

          {error && (
            <div className="flex items-center justify-center space-x-2 text-red-500">
              <AlertTriangle className="h-4 w-4" />
              <span className="text-sm">{error}</span>
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-teal-700/20 border border-teal-700/30 px-6 py-3 rounded transition-colors hover:bg-teal-700/30 flex items-center justify-center gap-2"
          >
            <Rocket className="h-5 w-5" />
            <span>Bundle Token Launch (starting 12/18)</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default LaunchPad;